import { useEffect, useState } from "react";
import { inventoryProducts } from "../page/Home";
import styles from "./Constant.module.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

type controltype = {
    inventory_id: number;
    constant: number;
}

export default function Constant() {

    const [products, setProducts] = useState<inventoryProducts[]>([]);
    const [selectedOffice, setSelectedOffice] = useState<string>("函館店");
    const [selectManufacture, setSelectManufacture] = useState<string[]>([]);
    const [selectedManufacture, setSelectedManufacture] = useState<string>("");
    const [selectedMoldingSite, setSelectedMoldingSite] = useState<string>("");
    const [constantControl, setConstantControl] = useState<controltype[]>([]);


    useEffect(() => {
        const fetchSelectInit = async () => {
            const response = await fetch("https://api.poweb.jp/getmanufacture");
            const data = await response.json();
            const Manufactures = data.manufacture.map((item: Object) => Object.values(item))
            setSelectManufacture(Manufactures);
        }
        fetchSelectInit();
    }, [])


    useEffect(() => {
        const fetchProducts = async () => {
            const response = await fetch("https://api.poweb.jp/", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "office": selectedOffice,
                    "manufacture": selectedManufacture,
                    "moldingsite": selectedMoldingSite
                })
            });
            const data: inventoryProducts[] = await response.json();
            setProducts(data);
        }

        fetchProducts();
    }, [selectedOffice, selectedManufacture, selectedMoldingSite])



    const handleOffice = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOffice(e.target.value);
    }

    const handleManufacture = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedManufacture(e.target.value);
    }

    const handleMoldingSite = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMoldingSite(e.target.value);
    }

    const increment = (index: number) => {
        const id = products[index].inventory_id;
        const newProducts: inventoryProducts[] = products.map((item) => {
            if (item.inventory_id === id) {
                return { ...item, constant: item.constant + 1 }
            }
            return item;
        })
        setProducts(newProducts);
        let newConstantControl: controltype[] = [...constantControl];
        const duplicationcc = newConstantControl.find(item => item.inventory_id === newProducts[index].inventory_id);
        if (duplicationcc) {
            duplicationcc.constant += 1;
        } else {
            newConstantControl.push({ inventory_id: newProducts[index].inventory_id, constant: newProducts[index].constant })
        }
        setConstantControl(newConstantControl)
    }

    const decrement = (index: number) => {
        const id = products[index].inventory_id;
        const newProducts: inventoryProducts[] = products.map((item) => {
            if (item.inventory_id === id) {
                return { ...item, constant: item.constant - 1 }
            }
            return item;
        })

        setProducts(newProducts);
        let newConstantControl: controltype[] = [...constantControl];
        const duplicationcc = newConstantControl.find(item => item.inventory_id === newProducts[index].inventory_id);
        if (duplicationcc) {
            duplicationcc.constant -= 1;
        } else {
            newConstantControl.push({ inventory_id: newProducts[index].inventory_id, constant: newProducts[index].constant })
        }
        setConstantControl(newConstantControl);
    }

    const handleConstant = async () => {
        if (constantControl.length === 0) {
            return alert("定数の変更がありません。")
        } else {
            const response = await fetch("https://api.poweb.jp/updateconstant", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(constantControl)
            })
            const data = await response.json();
            alert(data.message);
            setConstantControl([]);
        }
    }



    return (
        <div className={styles.div}>
            <div className={styles.div2}>
                <h2>在庫品の定数リスト</h2>
                <Link to="/ConstantRegister"><Button variant="contained" style={{ position: "absolute", top: 20, right: 50, fontSize: 16 }}>製品リストから定数を設定</Button></Link>
            </div>
            <div className={styles.selecterwrapper}>
                <select className={styles.select} value={selectedOffice} onChange={handleOffice}>
                    <option value="函館店">函館店</option>
                    <option value="札幌店">札幌店</option>
                    <option value="釧路店">釧路店</option>
                    <option value="室蘭店">室蘭店</option>
                </select>
                <select className={styles.select} value={selectedManufacture} onChange={handleManufacture} >
                    <option value="">未設定</option>
                    {selectManufacture.map(item => (<option key={item} value={item}>{item}</option>))}
                </select>
                <select className={styles.select} value={selectedMoldingSite} onChange={handleMoldingSite} >
                    <option value="">未設定</option>
                    <option value="A%">下肢装具</option>
                    <option value="D%">上肢装具</option>
                    <option value="C%">体幹装具</option>
                </select>
                <Button variant="contained" size="large" style={{ marginLeft: 100, fontSize: 16 }} onClick={handleConstant}>定数の変更を反映する</Button>
            </div>
            {!(products.length === 0) ? (<table className={styles.table} cellSpacing="5">
                <thead>
                    <tr><th className={styles.th}>メーカー</th>
                        <th className={styles.th}>製品名</th>
                        <th className={styles.th}>色</th>
                        <th className={styles.th}>左右</th>
                        <th className={styles.th}>サイズ</th>
                        <th className={styles.th}>在庫数</th>
                        <th className={styles.th}>在庫定数</th>
                        <th className={styles.th} colSpan={2}>在庫定数の変更</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((item, index) => (<tr key={index} className={styles.tr}>
                        <td className={styles.td}>{item.manufacture}</td>
                        <td className={styles.td}>{item.productname}</td>
                        <td className={styles.td}>{item.color}</td>
                        <td className={styles.td}>{item.left_right}</td>
                        <td className={styles.td}>{item.size}</td>
                        <td className={styles.td}>{item.quantity}</td>
                        <td className={styles.td2}>{item.constant}</td>
                        <td ><Button key={index} onClick={() => increment(index)} variant="contained" style={{ fontSize: 16 }}>+1</Button></td>
                        <td ><Button key={index} onClick={() => decrement(index)} disabled={item.constant < 1} variant="contained" style={{ fontSize: 16 }} > -1</Button></td>
                    </tr>
                    ))}
                </tbody>
            </table>) : <p>条件に一致する製品がありません。</p>
            }
        </div >
    )
}