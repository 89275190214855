import { useState, useEffect } from "react"
import styles from "./ConstantRegister.module.css"
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export type productData = {
    product_id: number;
    productname: string;
    manufacture: string;
    moldingsite: string;
    supplier: string;
    cost: number;
    leftright: string[];
    size: string[];
    color: string[];
}

type kind = {
    leftright: string;
    size: string;
    color: string;
    constant: number;
    office: string;
}

export default function ConstantRegister() {
    const navigate = useNavigate();

    const [selectedOffice, setSelectedOffice] = useState<string>("函館店");
    const [selectManufacture, setSelectManufacture] = useState<string[]>([]);
    const [selectedManufacture, setSelectedManufacture] = useState<string>("");
    const [products, setProducts] = useState<string[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<string>("");
    const [productData, setProductData] = useState<productData>();
    const [kinds, setKinds] = useState<kind[]>([]);

    useEffect(() => {
        const fetchSelectInit = async () => {
            const response = await fetch("https://api.poweb.jp/getmanufacture");
            const data = await response.json();
            const Manufactures = data.manufacture.map((item: Object) => Object.values(item))
            setSelectManufacture(Manufactures);
        }
        fetchSelectInit();
    }, [])

    useEffect(() => {
        const fetchSelectProducts = async () => {
            const response = await fetch("https://api.poweb.jp/selectproducts", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "manufacture": selectedManufacture
                })
            })
            const data = await response.json();
            const products = data.map((item: Object) => Object.values(item))
            setProducts(products);
        }

        fetchSelectProducts();

    }, [selectedManufacture]);

    const handleOffice = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOffice(e.target.value);
    }

    const handleManufacture = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedManufacture(e.target.value);
    }

    const handleProducts = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProduct(e.target.value);
    }

    const constantRender = async () => {
        const response = await fetch("https://api.poweb.jp/productdata", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                "productname": selectedProduct
            })
        })
        const data = await response.json();
        setProductData(data);

        if (data === undefined) {
            alert("製品データが確認できません。")
            return;
        }

        let Kinds: kind[] = [];

        for (let i = 0; data.color.length > i; i++) {
            for (let j = 0; data.leftright.length > j; j++) {
                for (let k = 0; data.size.length > k; k++) {
                    let Kind: kind = {
                        leftright: data.leftright[j],
                        size: data.size[k],
                        color: data.color[i],
                        constant: 0,
                        office: selectedOffice
                    };
                    Kinds.push(Kind);
                }
            }
        }
        setKinds(Kinds);
    }

    const increment = (index: number) => {
        const newKind = { ...kinds[index], constant: kinds[index].constant + 1 };
        const newKinds = [...kinds];
        newKinds.splice(index, 1, newKind);
        setKinds(newKinds);
    }
    const decrement = (index: number) => {
        const newKind = { ...kinds[index], constant: kinds[index].constant - 1 };
        const newKinds = [...kinds];
        newKinds.splice(index, 1, newKind);
        setKinds(newKinds);
    }

    const constantPush = async () => {
        const response = await fetch("https://api.poweb.jp/constantpush", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                "productname": selectedProduct,
                "kinds": kinds,
            })
        })
        const data = await response.json();
        console.log(data);
        navigate("/Constant");
    }


    return (
        <div className={styles.div}>
            <div><p>定数を追加する支店を選択してください。</p>
                <select value={selectedOffice} onChange={handleOffice} className={styles.select}>
                    <option value="函館店">函館店</option>
                    <option value="札幌店">札幌店</option>
                    <option value="釧路店">釧路店</option>
                    <option value="室蘭店">室蘭店</option>
                </select>
            </div>
            <div className={styles.selectwrapper}>
                <select value={selectedManufacture} onChange={handleManufacture} className={styles.select}>
                    <option value="">未設定</option>
                    {selectManufacture.map(item => (<option key={item} value={item}>{item}</option>))}
                </select>
                <select value={selectedProduct} onChange={handleProducts} className={styles.select}>
                    <option value="">未設定</option>
                    {products.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                </select>
                <Button onClick={constantRender} variant="contained" style={{ fontSize: 16 }}>選択した製品のリストを表示する</Button>
            </div>

            {(productData === undefined || !productData) ? (<p>定数を設定する製品が選択されていません。</p>) :

                (<div className={styles.div2}><Button onClick={constantPush} variant="contained" style={{
                    width: 150, height: 40, margin: 15, fontSize: 16
                }}>定数を反映する</Button>
                    <p>すでに定数が設定されている製品は定数が上書きされます。</p>
                    <table className={styles.table} cellSpacing="5">
                        <thead>
                            <tr>
                                <th className={styles.th}>製品名</th>
                                <th className={styles.th}>色</th>
                                <th className={styles.th}>左右</th>
                                <th className={styles.th}>サイズ</th>
                                <th className={styles.th}>在庫定数</th>
                                <th className={styles.th} colSpan={2}>在庫定数の変更</th>
                            </tr>
                        </thead>
                        <tbody>
                            {kinds.map((item, index) => (
                                <tr key={index}>
                                    <td className={styles.td}>{productData.productname}</td>
                                    <td className={styles.td}>{item.color}</td>
                                    <td className={styles.td}>{item.leftright}</td>
                                    <td className={styles.td}>{item.size}</td>
                                    <td className={styles.td2}>{item.constant}</td>
                                    <td ><Button key={index} onClick={() => increment(index)} variant="contained" style={{ fontSize: 16 }}>+1</Button></td>
                                    <td ><Button key={index} onClick={() => decrement(index)} disabled={item.constant < 1} variant="contained" style={{ fontSize: 16 }}>-1</Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table></div>)}
        </div>
    )
}