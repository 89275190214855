import { useState, useEffect } from "react";
import styles from "./SupplierTable.module.css"
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

export type SupplierData = {
    supplier_id: number;
    supplier: string;
    email: string;
    freeshipping: number;
}

export default function SupplierTable() {

    const navigate = useNavigate();

    const [suppliers, setSuppliers] = useState<SupplierData[]>([]);

    useEffect(() => {

        const fetchSupplier = async () => {
            const response = await fetch("https://api.poweb.jp/getsupplier")
            const data = await response.json();
            setSuppliers(data);
        }

        fetchSupplier();
    }, [suppliers])

    const handleEdit = async (index: number) => {
        const editsupplier = suppliers[index];
        navigate("/SupplierEditor", { state: editsupplier });
    }

    const handleDelete = async (index: number) => {
        const deletename = suppliers[index].supplier;
        if (window.confirm(`${deletename}を削除します。よろしいですか？`)) {
            try {
                const response = await fetch("https://api.poweb.jp/deletesupplier", {
                    method: "DELETE",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ "deletesupplier": deletename })
                })
                if (!response.ok) {
                    throw new Error("Network response was not OK");
                }
                alert("削除に成功しました。");
            } catch (error) {
                console.error(error);
            }
        } else {
            alert("削除をキャンセルしました。")
        }
    }


    return (
        <div className={styles.div}>
            <h2>発注先リスト</h2>
            <Link to="/SupplierRegister"><Button variant="contained" size="large" style={{ fontSize: 16 }} >発注先を新規登録する</Button></Link>
            <table className={styles.table}>
                <thead><tr><th className={styles.th}>発注先</th><th className={styles.th}>メールアドレス</th><th className={styles.th}>送料無料適用額</th></tr></thead>
                <tbody>
                    {suppliers && suppliers.map((item, index) => (<tr key={index} className={styles.tr}><td className={styles.td}>{item.supplier}</td><td className={styles.td}>{item.email}</td><td className={styles.td}>{item.freeshipping}</td><td><Button key={"update" + index} onClick={() => handleEdit(index)} style={{ fontSize: 16 }}>編集</Button></td><td><Button key={"delete" + index} onClick={() => handleDelete(index)} style={{ fontSize: 16 }}>削除</Button></td></tr>))}
                </tbody>
            </table>
        </div>
    )
}