import { useState, useEffect } from "react"
import styles from "./Entering.module.css"
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { productData } from "./ConstantRegister";

type kind = {
    leftright: string;
    size: string;
    color: string;
    quantity: number;
    office: string;
}


export default function ConstantRegister() {
    const [selectedOffice, setSelectedOffice] = useState<string>("");
    const [selectManufacture, setSelectManufacture] = useState<string[]>([]);
    const [selectedManufacture, setSelectedManufacture] = useState<string>("");
    const [products, setProducts] = useState<string[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<string>("");
    const [productData, setProductData] = useState<productData>();
    const [kinds, setKinds] = useState<kind[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchSelectInit = async () => {
            const response = await fetch("https://api.poweb.jp/getmanufacture");
            const data = await response.json();
            const Manufactures = data.manufacture.map((item: Object) => Object.values(item))
            setSelectManufacture(Manufactures);
        }
        fetchSelectInit();
    }, [])

    useEffect(() => {
        const fetchSelectProducts = async () => {
            const response = await fetch("https://api.poweb.jp/selectproducts", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "manufacture": selectedManufacture
                })
            })
            const data = await response.json();
            const products = data.map((item: Object) => Object.values(item))
            setProducts(products);
        }

        fetchSelectProducts();

    }, [selectedManufacture]);

    const handleOffice = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOffice(e.target.value);
    }

    const handleManufacture = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedManufacture(e.target.value);
    }

    const handleProducts = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProduct(e.target.value);
    }

    const quantityRender = async () => {
        const response = await fetch("https://api.poweb.jp/productdata", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                "productname": selectedProduct
            })
        })
        const data = await response.json();
        setProductData(data);

        if (data === undefined) {
            alert("製品データが確認できません。")
            return;
        }

        let Kinds: kind[] = [];

        for (let i = 0; data.color.length > i; i++) {
            for (let j = 0; data.leftright.length > j; j++) {
                for (let k = 0; data.size.length > k; k++) {
                    let Kind: kind = {
                        leftright: data.leftright[j],
                        size: data.size[k],
                        color: data.color[i],
                        quantity: 0,
                        office: selectedOffice
                    };
                    Kinds.push(Kind);
                }
            }
        }
        setKinds(Kinds);
    }

    const increment = (index: number) => {
        const newKind = { ...kinds[index], quantity: kinds[index].quantity + 1 };
        const newKinds = [...kinds];
        newKinds.splice(index, 1, newKind);
        setKinds(newKinds);
    }
    const decrement = (index: number) => {
        const newKind = { ...kinds[index], quantity: kinds[index].quantity - 1 };
        const newKinds = [...kinds];
        newKinds.splice(index, 1, newKind);
        setKinds(newKinds);
    }

    const quantityPush = async () => {
        const pushlist = [...kinds];
        const newkinds = pushlist.filter(item => item.quantity > 0);
        const response = await fetch("https://api.poweb.jp/quantitypush", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                "productname": selectedProduct,
                "kinds": newkinds,
            })
        })
        const data = await response.json();
        alert(data.message);
        navigate("/Control");
    }

    const resetOffice = () => {
        setSelectedOffice("");
        setSelectedManufacture("");
        setSelectedProduct("");
        setKinds([]);
    }

    return (
        <div className={styles.div}>
            <h2>製品入庫</h2>
            {(!selectedOffice) ? (<div><p>入庫する支店を選択してください。</p>
                <select value={selectedOffice} onChange={handleOffice} className={styles.select}>
                    <option value="" hidden>支店</option>
                    <option value="函館店">函館店</option>
                    <option value="札幌店">札幌店</option>
                    <option value="釧路店">釧路店</option>
                    <option value="室蘭店">室蘭店</option>
                </select>
            </div>) : <div className={styles.reset}><h3>{selectedOffice}</h3><Button onClick={resetOffice} variant="contained" style={{ margin: 15 }}>支店を変更する</Button></div>}
            {(selectedOffice) ? (
                <div className={styles.div}>
                    <div className={styles.selectwrapper}>
                        <select value={selectedManufacture} onChange={handleManufacture} className={styles.select}>
                            <option value="">未設定</option>
                            {selectManufacture.map(item => (<option key={item} value={item}>{item}</option>))}
                        </select>
                        <select value={selectedProduct} onChange={handleProducts} className={styles.select}>
                            <option value="">未設定</option>
                            {products.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                        </select>
                        <Button onClick={quantityRender} variant="contained" >選択した製品のリストを表示する</Button>
                    </div>

                    {productData && (<div className={styles.div}>
                        <Button onClick={quantityPush} variant="contained" style={{ width: 150, height: 40, margin: 15 }}>入庫する</Button>
                        <p>現在の在庫数に追加する数を設定してください。</p>
                        <table className={styles.table} cellSpacing="5">
                            <thead>
                                <tr>
                                    <th className={styles.th}>製品名</th>
                                    <th className={styles.th}>色</th>
                                    <th className={styles.th}>左右</th>
                                    <th className={styles.th}>サイズ</th>
                                    <th className={styles.th}>入庫数</th>
                                    <th className={styles.th} colSpan={2}>入庫数の設定</th>
                                </tr>
                            </thead>
                            <tbody>
                                {kinds.map((item, index) => (
                                    <tr key={index} className={styles.tr}>
                                        <td className={styles.td}>{productData.productname}</td>
                                        <td className={styles.td}>{item.color}</td>
                                        <td className={styles.td}>{item.leftright}</td>
                                        <td className={styles.td}>{item.size}</td>
                                        <td className={styles.td2}>{item.quantity}</td>
                                        <td ><Button key={index} onClick={() => increment(index)} variant="contained" >+1</Button></td>
                                        <td ><Button key={index} onClick={() => decrement(index)} disabled={item.quantity < 1} variant="contained">-1</Button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table></div>)}
                </div>) : null}
        </div>
    )
}