import { NavLink } from "react-router-dom"
import styles from "./Sidebar.module.css"
import { useState } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

export default function Sidebar() {

    const [toggle1, setToggle1] = useState<boolean>(false);
    const [toggle2, setToggle2] = useState<boolean>(false);
    const [toggle3, setToggle3] = useState<boolean>(false);

    return (
        <aside className={styles.sidebar}>
            <nav className={styles.sticky}>
                <div className={styles.ul}>
                    <NavLink to="/" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.list}><WarehouseIcon style={{ marginRight: 10 }} />在庫一覧</li></NavLink>
                    <li className={styles.liOpen} onClick={() => setToggle1(!toggle1)}><SyncAltIcon style={{ marginRight: 10 }} />入出庫{toggle1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</li>
                    {toggle1 ? <div className={styles.toggleUl}>
                        <NavLink to="/control" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>在庫一覧から入出庫</li></NavLink>
                        <NavLink to="/ordered" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>発注済未入庫から入庫</li></NavLink>
                        <NavLink to="/entering" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>登録製品から入庫</li></NavLink>
                    </div> : null}
                    <li onClick={() => setToggle2(!toggle2)} className={styles.liOpen}><Inventory2TwoToneIcon style={{ marginRight: 10 }} />発注{toggle2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</li>
                    {toggle2 ? <div className={styles.toggleUl}>
                        <NavLink to="/order" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>発注</li></NavLink>
                        <NavLink to="/history" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>発注履歴</li></NavLink>
                        <NavLink to="/Suppliers" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>発注先リスト</li></NavLink>
                        {/* <li className={styles.togglelist}>受信メール確認</li> */}
                    </div> : null}
                </div>
                <div className={styles.ul2}>
                    <li className={styles.liOpen} onClick={() => setToggle3(!toggle3)}><ContentPasteSearchIcon style={{ marginRight: 10 }} />管理{toggle3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</li>
                    {toggle3 ? <div className={styles.toggleUl}>
                        <NavLink to="/SupplierTable" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>発注先の編集</li></NavLink>
                        <NavLink to="/Manufacture" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>メーカーの編集</li></NavLink>
                        <NavLink to="/Products" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>製品の編集</li></NavLink>
                        <NavLink to="/constant" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>定数の編集</li></NavLink>
                        <NavLink to="AutomaticOrderingTable" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>自動発注管理</li></NavLink>
                    </div> : null}
                </div>
            </nav>
        </aside >
    )

}