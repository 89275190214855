import { useForm, SubmitHandler } from "react-hook-form"
import styles from "./SupplierRegister.module.css"
import { SupplierData } from "./SupplierTable"
import { Button } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"

export default function SupplierRegister() {

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SupplierData>(
        { mode: 'onBlur' }
    )

    const onSubmit: SubmitHandler<SupplierData> = async (data) => {
        const datastr = JSON.stringify(data);

        const response = await fetch("https://api.poweb.jp/createsupplier", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: datastr
        })
        const resjson = await response.json();
        alert(resjson.massage);
        if (resjson.submit) {
            navigate("/SupplierTable");
        }
    }

    return (

        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <h2>発注先の登録</h2>
            <p>発注先（メーカー、販売店）の名称を入力してください。（必須）<br />
                （全角で入力してください。）</p>
            <input type="text"
                {...register("supplier",
                    {
                        required: "発注先の名称は必須です。",
                        maxLength: {
                            value: 50, message: "50文字以内で入力してください。"
                        },
                        pattern: { value: /^[\u4E00-\u9FFFぁ-んァ-ヶ０-９ー・]+$/, message: "全角で入力してください。" },
                    })} className={styles.input} required />
            <span>{errors.supplier?.message}</span>
            <p>発注用のメールアドレスを入力してください。</p>
            <input type="email" {...register("email", {
                pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "正しいメールの型式で入力してください。"
                }
            })} className={styles.input} />
            <span>{errors.email?.message}</span>
            <p>送料無料になる注文金額を入力してください。<br />
                （半角数字で入力してください。）</p>
            <input type="number" {...register("freeshipping")} className={styles.input} />
            <span>{errors.freeshipping?.message}</span>
            <p>メールアドレスを登録すると、アプリから発注メールを送ることができます。<br />
                送料無料の金額を入力すると自動発注の条件として設定することができます。</p>
            <Button type="submit" variant="contained" style={{ margin: 15 }} >登録</Button>
            <Link to="/SupplierTable"><Button>キャンセル</Button></Link>
        </form>

    )
}